import vert from './line.vert';
import frag from './line.frag';
import mat4 from 'gl-mat4';

const rotate = ([x, z], phi) => [
  x * Math.cos(phi) + z * Math.sin(phi),
  -x * Math.sin(phi) + z * Math.cos(phi),
];

/**
 * @param {number} extrusionSegments 分割线 两条为一层
 * @param {number} radiusSegments 几边形
 */
const getLine = (offset = [0, 0, 0, 0], extrusionSegments = 50, radiusSegments = 5) => {
  const radius = 0.01;
  const indices = [];
  const position = [];
  const uv = [];

  for (let i = 0; i < extrusionSegments; i += 1) {
    const y = 2 * (1 - i / extrusionSegments) - 1;

    for (let j = 0; j < radiusSegments; j += 1) {
      const phi = (2 * Math.PI * j) / radiusSegments;
      const x = radius * Math.cos(phi);
      const z = radius * Math.sin(phi);
      const xz = rotate([x, z], offset[3]);

      // uv 不能加上 offset，会大于1
      // 此时的xz[0] 值受到了radius的限值，所以uv的值可能就是0.01附近，拿取图中的像素也就是一小部分
      // y 倒是 -1 ~ 1
      uv.push([(xz[0] / (radius * 1)) * 0.5 + 0.5, y * 0.5 + 0.5]);
      const offsetPos = [...[xz[0], y, xz[1], 0].map((v, i) => v + offset[i])];
      position.push(offsetPos);

      // 0 1
      // 3 4
      // => 0 3 1 | 1 3 4 | 1 4 0 | 0 4 3
      if (i < extrusionSegments - 1) {
        const topLeft = i * radiusSegments + j;
        const topRight = j === radiusSegments - 1 ? topLeft - (radiusSegments - 1) : topLeft + 1;
        const bottomLeft = topLeft + radiusSegments;
        const bottomRight = topRight + radiusSegments;
        indices.push(topLeft, bottomLeft, topRight, topRight, bottomLeft, bottomRight);
      }
    }
  }
  return { position, uv, indices, offset: position.map(() => offset) };
};

export const initLineCommand = (regl, image) => {
  const num = 30;
  const ratio = 0.5;
  const offsets = Array(num)
    .fill(0)
    .map((v, i) => [
      ratio * Math.cos((2 * Math.PI * i) / num),
      0,
      ratio * Math.sin((2 * Math.PI * i) / num),
      Math.PI / 2 - (2 * Math.PI * i) / num,
    ]);

  const lines = offsets.map((offset) => getLine([...offset]));
  const position = lines.flatMap((line) => line.position);
  const uv = lines.flatMap((line) => line.uv);

  const indices = [];
  let cnt = 0;
  lines.forEach((line) => {
    indices.push(...line.indices.map((i) => i + cnt));
    cnt += line.position.length;
  });

  return regl({
    vert,
    frag,
    attributes: {
      a_position: position,
      a_uv: uv,
    },
    uniforms: {
      u_texture: regl.texture({ data: image, flipY: true }),
      u_res: (_, props) => props.res,
      u_time: ({ time }) => time,
      view: () => mat4.lookAt([], [0, 1, 0], [0, 0, 0], [1, 0, 0]),
      projection: ({ viewportWidth, viewportHeight }) =>
        mat4.perspective([], Math.PI / 2, viewportWidth / viewportHeight, 0.01, 100),
    },
    elements: indices,
    depth: { enable: false },
    cull: {
      enable: false,
      face: 'front',
    },
    blend: {
      enable: true,
      func: {
        src: 'src alpha',
        dst: 'one minus src alpha',
      },
    },
  });
};
