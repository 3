export const loadImg = (img) => {
  return new Promise((resolve) => {
    img.onload = () => {
      resolve(img);
    };
  });
};

export const resize = (canvas) => {
  const onResize = () => {
    canvas.width = window.innerWidth * window.devicePixelRatio;
    canvas.height = window.innerHeight * window.devicePixelRatio;
  };
  window.addEventListener('resize', onResize);
  onResize();
};
