<template lang="pug">
.stats
canvas.canvas(ref='canvas')
img(ref='img' src='~@/assets/scacchiera.jpg' style='display: none')
</template>

<script>
window.__DEBUG__ = false;

import { onBeforeUnmount, onMounted, ref } from 'vue';
import createCamera from 'regl-camera';
import createREGL from 'regl';
import { initLineCommand } from '@/commands/vooc/line/index.js';
import { loadImg, resize } from '../ImageEffect/common';

export default {
  name: 'Line',
  setup() {
    const canvas = ref(null);
    const img = ref(null);

    let regl;

    onMounted(async () => {
      resize(canvas.value);

      regl = createREGL({ canvas: canvas.value });

      const imgEl = await loadImg(img.value);

      const draw = initLineCommand(regl, imgEl);
      const camera = createCamera(regl, {
        center: [0, 0, 0],
        distance: 0,
        damping: 0,
        theta: 0,
        phi: 0,
        fovy: Math.PI / 8,
      });

      regl.frame(() => {
        // camera(() => {
        //   draw({ res: [canvas.value.width, canvas.value.height] });
        // });

        draw({ res: [canvas.value.width, canvas.value.height] });
      });
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, img };
  },
};
</script>

<style lang="stylus" scoped>
@import '../ImageEffect/common.styl'
</style>
